import { selectLink } from '@hurleymc/sdk/selectors'
import { PageBuilderFeaturedText } from '@hurleymc/sdk/typings'
import * as React from 'react'
import styled from 'styled-components'

import { ChevyLink } from '../common'
import { Col, Container, Row, Spacer, Typography } from '../../../../components'

const Component = styled.div``
const Center = styled.div`
  text-align: center;
`

interface FeaturedTextProps extends PageBuilderFeaturedText {
  className?: string
  components: { Link: (props: any) => JSX.Element }
}

export default (props: FeaturedTextProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  const Link = props.components.Link
  const linkProps = selectLink(props)

  return (
    <Component>
      <Spacer margin={{ xs: '6rem .5rem', lg: '8rem .5rem' }}>
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col md={5 / 6}>
              <Center>
                <Typography variant='heading-6' gutterBottom={true}>{props.identifier}</Typography>
                {
                  props.summary &&
                  props.summary.content &&
                  <Typography component='div' dangerouslySetInnerHTML={{ __html: props.summary.content }} variant='body2' />
                }
                <ChevyLink align='center' color='default' component={Link} {...linkProps}>{linkProps.title}</ChevyLink>
              </Center>
            </Col>
          </Row>
        </Container>
      </Spacer>
    </Component>
  )
}
