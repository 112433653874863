import {
  PageBuilderCode
} from '@hurleymc/sdk/typings'

import { Typography } from '../../../../components'

interface CodeProps extends PageBuilderCode {
  className?: string
}

import React, { useState, useEffect } from 'react';

export default function PageBuilderCodeComponent(props: CodeProps) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      setContent(props && props.html && props.html.content ? props.html.content : null);
    };

    fetchContent();
  }, []);

  if (!content) {
    return null;
  }

  return (
    <Typography component='div' dangerouslySetInnerHTML={{ __html: content }} variant='body' />
  );
}
