import {
  selectLink
} from '@hurleymc/sdk/selectors'

import {
  FormBlackbaud,
  FormBlackbaudV2,
  FormSurveyGizmo,
  PageBuilderForm
} from '@hurleymc/sdk/typings'

import * as React from 'react'
import SurveyGizmo from './FormSurveyGizmo'
import Blackbaud from './FormBlackbaud'
import BlackbaudV2 from './FormBlackbaudV2'
import { Col, Container, Row, Spacer } from '../../../../components'

interface PageBuilderFormProps extends PageBuilderForm {
  form: { __typename: string }[] & FormSurveyGizmo[] & FormBlackbaud[]
}

export default (props: PageBuilderFormProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  if (props.form && props.form.length > 0 && props.form[0].__typename) {
    switch (props.form[0].__typename) {
      case 'FormBlackbaud':
        const blackbaud = props.form[0] as FormBlackbaud
        return (
          <Spacer margin='2rem .5rem'>
            <Container>
              <Row xsJustifyContentCenter={true}>
                <Col lg={5 / 6}>
                  <Blackbaud id='bbox-root' formId={blackbaud.formId} />
                </Col>
              </Row>
            </Container>
          </Spacer>
        )
        case "FormBlackbaudV2":
          const blackbaudV2 = props.form[0] as FormBlackbaudV2;
          const blackbaudFormV2Options = blackbaudV2.blackbaudFormV2Options[0];
          return (
            <Spacer margin="2rem .5rem">
              <Container>
                <Row xsJustifyContentCenter={true}>
                  <Col lg={5 / 6}>
                    <BlackbaudV2
                      formEnvId={blackbaudFormV2Options.formEnvId}
                      formHeaderHeight={blackbaudFormV2Options.formHeaderHeight}
                      formId={blackbaudFormV2Options.formId}
                      formZone={blackbaudFormV2Options.formZone}
                    />
                  </Col>
                </Row>
              </Container>
            </Spacer>
          );
      case 'FormSurveyGizmo':
        const surveyGizmo = props.form[0] as FormSurveyGizmo
        return (
          <Spacer margin='2rem .5rem'>
            <Container>
              <Row xsJustifyContentCenter={true}>
                <Col lg={5 / 6}>
                  <SurveyGizmo header={surveyGizmo.header} surveyId={surveyGizmo.surveyId} surveyName={surveyGizmo.surveyName} />
                </Col>
              </Row>
            </Container>
          </Spacer>
        )
      default:
        return null
    }
  }

  return null
}
